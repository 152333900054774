// Slide
.fixed-top,
.sb-nav-fixed #layoutSidenav #layoutSidenav_nav,
.sb-nav-fixed .sb-topnav {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

#layoutAuthentication {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  #layoutAuthentication_content {
    min-width: 0;
    flex-grow: 1;
  }
  #layoutAuthentication_footer {
    min-width: 0;
  }
}

#layoutSidenav {
  display: flex;
  #layoutSidenav_nav {
    flex-basis: 225px;
    flex-shrink: 0;
    transition: transform 0.15s ease-in-out;
    z-index: 1038;
    transform: translateX(-225px);
  }
  #layoutSidenav_content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 0;
    flex-grow: 1;
    min-height: calc(100vh - 56px);
    margin-left: -225px;
  }
}
.sb-sidenav-toggled {
  #layoutSidenav {
    #layoutSidenav_nav {
      transform: translateX(0);
    }
    #layoutSidenav_content {
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: #000;
        z-index: 1037;
        opacity: 0.5;
        transition: opacity 0.3s ease-in-out;
      }
    }
  }
}

@media (min-width: 992px) {
  #layoutSidenav #layoutSidenav_nav {
    transform: translateX(0);
  }
  #layoutSidenav #layoutSidenav_content {
    margin-left: 0;
    transition: margin 0.15s ease-in-out;
  }

  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_nav {
    transform: translateX(-225px);
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content {
    margin-left: -225px;
  }
  .sb-sidenav-toggled #layoutSidenav #layoutSidenav_content:before {
    display: none;
  }
}
.sb-nav-fixed {
  .sb-topnav {
    z-index: 1039;
    background-color: $login-head !important;
  }
  #layoutSidenav {
    #layoutSidenav_nav {
      width: 225px;
      height: 100vh;
      z-index: 1038;
      .sb-sidenav {
        padding-top: 56px;
        .sb-sidenav-menu {
          overflow-y: auto;
        }
      }
    }
    #layoutSidenav_content {
      padding-left: 225px;
      top: 56px;
    }
  }
}
.nav .nav-link .sb-nav-link-icon,
.sb-sidenav-menu .nav-link .sb-nav-link-icon {
  margin-right: 0.5rem;
}
.sb-topnav {
  padding-left: 0;
  height: 56px;
  z-index: 1039;
  .navbar-brand {
    width: 225px;
    padding-left: 1rem;
    padding-right: 1rem;
    margin: 0;
  }
  &.navbar-dark {
    #sidebarToggle {
      color: rgba(255, 255, 255, 0.5);
    }
  }
  &.navbar-light {
    #sidebarToggle {
      color: #212529;
    }
  }
  &.bg-white {
    border-bottom: 1px solid #eaeaea;
    background-color: #fff;
  }
  button {
    height: auto !important;
  }
}
.sb-sidenav {
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-wrap: nowrap;
  .sb-sidenav-menu {
    .collapse.show {
      display: block;
      background-color: #f8f8f8;
    }
    flex-grow: 1;
    .nav {
      flex-direction: column;
      flex-wrap: nowrap;
      .sb-sidenav-menu-heading {
        padding: 1.75rem 1rem 0.75rem;
        font-size: 0.75rem;
        font-weight: bold;
        text-transform: uppercase;
      }
      .nav-link {
        display: flex;
        align-items: center;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        position: relative;
        border-bottom: 1px solid #eaeaea;
        .sb-nav-link-icon {
          font-size: 0.9rem;
        }
        .sb-sidenav-collapse-arrow {
          display: inline-block;
          margin-left: auto;
          transition: transform 0.15s ease;
        }
        &.collapsed {
          .sb-sidenav-collapse-arrow {
            transform: rotate(-90deg);
          }
        }
      }
      .sb-sidenav-menu-nested {
        flex-direction: column;
      }
    }
  }
  .sb-sidenav-footer {
    padding: 0.75rem;
    flex-shrink: 0;
  }
}
.sb-sidenav-dark {
  background-color: #212529;
  color: rgba(255, 255, 255, 0.5);
  .sb-sidenav-menu {
    .sb-sidenav-menu-heading {
      color: rgba(255, 255, 255, 0.25);
    }
    .nav-link {
      color: rgba(255, 255, 255, 0.5);
      .sb-nav-link-icon {
        color: rgba(255, 255, 255, 0.25);
      }
      .sb-sidenav-collapse-arrow {
        color: rgba(255, 255, 255, 0.25);
      }
      &:hover {
        color: #fff;
      }
      &.active {
        color: #fff;
        .sb-nav-link-icon {
          color: #fff;
        }
      }
    }
  }
  .sb-sidenav-footer {
    background-color: #343a40;
  }
}
.sb-sidenav-light {
  background-color: #ffffff;
  color: #212529;
  .sb-sidenav-header {
    padding: 0.75rem;
    flex-shrink: 0;
    background-color: $crl-2;
    color: $white;
  }
  .sb-sidenav-lbtext {
    padding: 0.75rem;
    flex-shrink: 0;
    background-color: $crl-3;
    color: $white;
  }
  .sb-sidenav-menu {
    .sb-sidenav-menu-heading {
      color: #adb5bd;
    }
    .nav-link {
      color: #212529;
      .sb-nav-link-icon {
        color: #adb5bd;
      }
      .sb-sidenav-collapse-arrow {
        color: #adb5bd;
      }
      &:hover {
        color: $crl-2;
      }
      &.nav-link-active {
        color: $crl-2;
        background-color: $light-b-color;
        .sb-nav-link-icon {
          color: $crl-2;
        }
      }
    }
    .sidenav-disabled {
      background-color: rgb(165, 165, 165);
      color: rgb(108, 107, 107) !important;
      cursor: no-drop;
      &:hover {
        color: rgb(108, 107, 107) !important;
      }
    }
  }
  .sb-sidenav-footer {
    background-color: #e9ecef;
  }
}
.c__nav {
  .navbar-nav {
    .nav-link {
      &:hover {
        // background: #f7f9fa;
      }
      .fa {
        color: #9d9fa2;
      }
    }
    .navlink-item {
      color: rgba(0, 0, 0, 0.5);
      padding: 17px 15px !important;
      font-size: 14px;
      float: left;
      margin-right: 2px;
      cursor: pointer;
      border-radius: 0px;
      height: auto;
    }

    .nan-link-profile {
      img {
        border-radius: 50% !important;
        width: 40px;
        height: 40px;
        border: 1px solid #eaeaea;
        object-fit: contain;
      }
    }
  }
  padding: 0px !important;
  .navbar-brand {
    background-color: $login-head;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    img {
      height: 40px;
      max-width: 130px;
    }
  }

  .hmr_btn {
    padding: 16px 20px 16px 20px !important;
    font-size: 14px;
    float: left;
    border-right: 1px solid #eaeaea;
    border-left: 1px solid #eaeaea;
    margin-right: 15px;
    background: #f7f9fa;
    cursor: pointer;
    border-radius: 0px;
    height: 40px !important;
  }
}
