html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
article,
aside,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section {
  display: block;
}
html,
body {
  height: 100%;
}
.h-100 {
  height: 100%;
}
.w-100 {
  width: 100%;
}
.col-flex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.align-right {
  text-align: right;
}
.align-left {
  text-align: left;
}
body {
  margin: 0;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  line-height: 1.5;
  color: $body-primary-color;
  text-align: left;
  background: #f1f3f6;
}
/*
breadcrumb Start*/

.normalheader {
  .hpanel {
    margin-bottom: 0;
    background-color: none;
    border: 0;
    box-shadow: none;
    margin-bottom: 25px;
    .panel-body {
      .m-t-lg {
        margin-top: 30px;
      }
      background: #fff;
      border: 1px solid #eaeaea;
      border-radius: 2px;
      padding: 20px;
      position: relative;
      .m-head {
        h1 {
          font-weight: 300;
          font-size: 30px;
          margin-top: 0px !important;
        }
        small {
          font-size: 15px;
        }
      }
      .hbreadcrumb {
        padding: 2px 0;
        margin-top: 6px;
        margin-bottom: 0;
        list-style: none;
        background-color: #fff;
        font-size: 12px;
        .active {
          color: #9d9fa2;
        }
        li {
          display: inline-block;
        }
        li + li {
          &:before {
            padding: 0 5px;
            color: #34495e;
            content: "/\00a0";
          }
        }
      }
    } /*panel-body End*/
  }
}
/*
breadcrumb Start End*/
.norml-bd-pd-y {
  padding: 15px;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

a {
  color: $crl-2;
  text-decoration: none;
  background-color: transparent;
  &:hover {
    color: #0056b3;
    text-decoration: none;
  }
  &:not([href]) {
    color: inherit;
    text-decoration: none;
  }
  &:not([href]):hover {
    color: inherit;
    text-decoration: none;
  }
}
img {
  vertical-align: middle;
  border-style: none;
}

// Formsarea End

.filter-holder {
  &.filter-flex {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }
  .filter-box {
    margin: 5px 5px 5px 0px;
    &:last-child {
      margin-right: 0px;
    }
  }
}

.installation-container {
  text-align: center;
  .success-head {
    color: $body-primary-color;
  }
  .document-list {
    width: 100%;
    ul {
      background-color: #f3f3f3;
      border-color: #f8f9fa;
      color: #293250;
      text-align: left;
      padding: 15px;
      list-style: none;
    }
    p {
      font-weight: 400;
      font-size: 14px;
      text-align: left;
      margin-bottom: 7px;
      color: #c3c3c3;
    }

    li {
      padding-left: 5px;
      padding-bottom: 5px;
      position: relative;
      font-size: 20px;
      color: #a7a7a7;

      span {
        padding-left: 20px;
      }
      .fa {
        position: "absolute";
        left: 0px;
        width: 15px;
        height: 15px;
      }
      .clr-pending {
        color: #a7a7a7;
      }
      .clr-success {
        color: $green;
      }
      .clr-failed {
        color: $red;
      }
    }
  }
}

.add-booth-item {
  width: "100%";
  background-color: #eee;
  margin-bottom: 5px;
  h6 {
    font-size: 18px;
  }
}

.header-img {
  height: 50px;
}

.ward-head {
  color: black;
  font-size: large;
}

.react-datepicker-wrapper {
  width: 100% !important;
}

.color-black {
  color: black;
}

.custom-chart-tooltip {
  color: #000000;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 8px;
  padding: 5px;
  .label {
    padding: 0;
    margin: 0;
  }
  .desc {
    padding: 0;
    margin: 0;
  }
}

.border-left-table {
  border-top-width: 0px !important;
  border-left-width: 5px !important;
}

.datePickerFixed {
  .react-datepicker__tab-loop {
    position: fixed;
  }
}

.dialogHeight {
  // height: 80% !important;
  .MuiDialog-container {
    height: 80% !important;
  }
}
