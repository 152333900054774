.custom-table {
  table {
    th,
    td {
      font-size: 14px;
      vertical-align: middle;
    }
    thead {
      tr th {
        &:first-child {
          width: 25px;
        }
      }
    }

    tr {
      &:nth-of-type(odd) {
        background-color: rgb(252, 252, 252);
      }
    }
  }
}

.product-table {
  table {
    th,
    td {
      font-size: 14px;
      vertical-align: middle;
    }

    tr {
      &:nth-of-type(odd) {
        background-color: rgb(252, 252, 252);
      }
    }
  }
}
