.admin_profile {
  .col-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 0px;
  }

  .prooflist {
    ul {
      margin: 0px;
      padding: 0px;
      li {
        list-style: none;
        font-size: 14px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 20px;
        &.id_aprove:before {
          content: url(../../../img/approve-icon.png);
          line-height: 10px;
          position: absolute;
          left: 0px;
          top: 3px;
        }
        &.hidetext:before {
          content: url(../../../img/pending-icon.png);
          line-height: 10px;
          position: absolute;
          left: 0px;
          top: 3px;
          -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
          filter: grayscale(100%);
        }

        &.hidetext {
          color: $labelclr;
        }
      }
    }
  }
  .profile-head {
    text-align: center;
    width: 50%;
    &:first-child {
      border-right: 1px solid $c-border;
    }
    span {
      font-size: 14px;
      color: $labelclr;
    }
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin: 5px 0px 1px 0px;
    }
  }

  .avatar-holder {
    text-align: center;
    .avatar-md {
      border-radius: 50% !important;
      width: 100px;
      height: 100px;
      object-fit: cover;
    }
    .avatar-name {
      margin: 10px 10px;
      ._name {
        font-size: 18px;
        font-weight: 600;
        margin: 3px 0px 1px 0px;
      }
      ._mobno {
        font-size: 15px;
        font-weight: 600;
        margin: 3px 0px 1px 0px;
      }
      ._email {
        font-size: 15px;
        color: $labelclr;
      }
      ._address {
        font-size: 15px;
        font-weight: 500;
        margin: 3px 0px 1px 0px;
      }
    }
  }
}

.profile-details {
  background-color: $white;
  padding: 1.25rem;
  .blockwise {
    h3 {
      font-size: 16px;
      font-weight: 600;
      margin: 0px 0px 1px 0px;
    }
    span {
      font-size: 15px;
      color: $labelclr;
    }
  }

  .border-none {
    th,
    td {
      border: none;
      background-color: none;
    }

    tr {
      &:nth-of-type(odd) {
        background: none !important;
      }
    }
  }
  .left-right-tb {
    tr td:first-child {
      text-align: left;
      min-width: 160px;
      font-size: 14px;
      font-weight: 600;
    }
  }
  .left-right-tb {
    tr td:last-child {
      text-align: left;
    }
  }
}

.thumb-img {
  img {
    width: 100px;
    height: 100px;
    object-fit: cover;
  }
}
