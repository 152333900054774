.loader-fixed {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 100%;
  z-index: 1030;
}

.bodyloader {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  background-color: white;
  z-index: 99;
}

.filterLoader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 5rem;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 1099;
  height: 100%;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

//Roller
.lds-roller {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 32px 32px;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: $btn-primary; //#609;
      margin: -3px 0 0 -3px;
    }
    &:nth-child(1) {
      animation-delay: -0.036s;
    }
    &:nth-child(1):after {
      top: 50px;
      left: 50px;
    }
    &:nth-child(2) {
      animation-delay: -0.072s;
    }
    &:nth-child(2):after {
      top: 54px;
      left: 45px;
    }
    &:nth-child(3) {
      animation-delay: -0.108s;
    }
    &:nth-child(3):after {
      top: 57px;
      left: 39px;
    }
    &:nth-child(4) {
      animation-delay: -0.144s;
    }
    &:nth-child(4):after {
      top: 58px;
      left: 32px;
    }
    &:nth-child(5) {
      animation-delay: -0.18s;
    }
    &:nth-child(5):after {
      top: 57px;
      left: 25px;
    }
    &:nth-child(6) {
      animation-delay: -0.216s;
    }
    &:nth-child(6):after {
      top: 54px;
      left: 19px;
    }
    &:nth-child(7) {
      animation-delay: -0.252s;
    }
    &:nth-child(7):after {
      top: 50px;
      left: 14px;
    }
    &:nth-child(8) {
      animation-delay: -0.288s;
    }
    &:nth-child(8):after {
      top: 45px;
      left: 10px;
    }
  }
}
.b-loder {
  overflow: hidden;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
