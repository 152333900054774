.custom-checkbox {
  .custom-control-input {
    &:checked {
      & ~ .custom-control-label {
        &::before {
          background-color: $checkbox-p;
          border: 1px solid $checkbox-p;
          box-shadow: none;
        }
      }
    }
  }
  .custom-control-label {
    padding-left: 8px;
    &.checkbox-primary {
      &:before {
        background-color: $checkbox-p;
        border: 1px solid $checkbox-p;
      }
    }
    &:before {
      width: 1.3rem;
      height: 1.3rem;
      border: 1px solid #bfbfbf;
      border-radius: 0px !important;
      background-color: $white;
      top: 0px;
    }
    &:after {
      width: 1.3rem;
      height: 1.3rem;
      top: 0px;
      background-size: 53% 52%;
    }
  }
}
