@import url("https://rsms.me/inter/inter.css");
// Formsarea
input,
button,
select {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border-radius: 0px !important;
  height: calc(2.25rem + 2px);
  &:focus {
    box-shadow: none !important;
    border-color: $crl-2 !important;
  }
}
button,
input {
  overflow: visible;
}
button,
select {
  text-transform: none;
}
select {
  word-wrap: normal;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}
button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}
input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}
input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}
textarea {
  overflow: auto;
  resize: vertical;
}

.form-holder {
  label {
    color: #8f8c8c;
    font-size: 16px;
    margin-bottom: 9px !important;
  }
  .required:after {
    content: " *";
    color: red;
  }
}
::-webkit-input-placeholder {
  /* Edge */
  color: $c-border !important;
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: $c-border !important;
}

::placeholder {
  color: $c-border !important;
}

.admin_profile {
  .prefix-wrp {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    .prfx-1 {
      width: 100px !important;
      margin-right: 5px;
    }
    .form-holder {
      width: 100%;
    }
  }
}

.cntry-code {
  position: relative;
  .c-code {
    position: absolute;
    left: 7px;
    top: 10px;
    border-right: 1px solid #ccc;
    padding-right: 8px;
    font-weight: 600;
  }
  input {
    padding-left: 50px;
  }
}

.grey-label {
  color: #8f8c8c;
  font-size: 16px;
}

.select-search {
  background: white;
  .select-search__input {
    border: 1px solid $login-border;
    display: block;
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    &:focus {
      box-shadow: none;
      border-color: none;
    }
  }
}

.select-search__select {
  position: relative;
  left: 0px;
  right: 25px;
  opacity: 1;
  z-index: 1000000 !important;

  .select-search__options {
    max-height: 200px;
    overflow: scroll;
    z-index: 2000;
    background: #ffffff;
    opacity: 1;
    li {
      list-style: none;
      opacity: 1;
      width: 100%;
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
      border-radius: 0px !important;
      height: calc(2.25rem + 2px);
      box-shadow: none !important;
      cursor: pointer;
      &:focus {
        box-shadow: none !important;
        border-color: $crl-2 !important;
      }
      button {
        background: none;
        border: none;
      }
    }
  }
}

.popup-select-search {
  .select-search__options {
    position: absolute;
    width: 100%;
  }
}

.select-search-container {
  --select-search-background: #ffffff !important;
  --select-search-border: 1px solid #ced4da !important;
  --select-search-text: #000;
  --select-search-highlight: grey;
  --select-search-subtle-text: #000;
}

.select-search-input {
  height: 38px !important;
}

@media (prefers-color-scheme: dark) {
  .select-search-container {
    background-color: #ffffff !important;
    border: 1px solid #ced4da !important;
  }
}

/**
 * React Select Search  Main wrapper
 */
.select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-inverted-text: var(--select-search-background);
  --select-search-highlight: #eff1f5;
  --select-search-font: "Inter", sans-serif;

  width: 300px;
  position: relative;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  box-sizing: border-box;
}

@supports (font-variation-settings: normal) {
  .select-search-container {
    --select-search-font: "Inter var", sans-serif;
  }
}

body.is-dark-mode .select-search-container {
  --select-search-background: #000;
  --select-search-border: #313244;
  --select-search-selected: #89b4fa;
  --select-search-text: #fff;
  --select-search-subtle-text: #a6adc8;
  --select-search-highlight: #1e1e2e;
}

body.is-light-mode .select-search-container {
  --select-search-background: #fff;
  --select-search-border: #dce0e8;
  --select-search-selected: #1e66f5;
  --select-search-text: #000;
  --select-search-subtle-text: #6c6f85;
  --select-search-highlight: #eff1f5;
}

.select-search-container *,
.select-search-container *::after,
.select-search-container *::before {
  box-sizing: inherit;
}

.select-search-input {
  position: relative;
  z-index: 1;
  display: block;
  height: 48px;
  width: 100%;
  padding: 0 40px 0 16px;
  background: var(--select-search-background);
  border: 2px solid var(--select-search-border);
  color: var(--select-search-text);
  border-radius: 3px;
  outline: none;
  font-family: var(--select-search-font);
  font-size: 16px;
  text-align: left;
  text-overflow: ellipsis;
  line-height: 48px;
  letter-spacing: 0.01rem;
  -webkit-appearance: none;
  -webkit-font-smoothing: antialiased;
}

.select-search-is-multiple .select-search-input {
  margin-bottom: -2px;
}

.select-search-is-multiple .select-search-input {
  border-radius: 3px 3px 0 0;
}

.select-search-input::-webkit-search-decoration,
.select-search-input::-webkit-search-cancel-button,
.select-search-input::-webkit-search-results-button,
.select-search-input::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.select-search-input[readonly] {
  cursor: pointer;
}

.select-search-is-disabled .select-search-input {
  cursor: not-allowed;
}

.select-search-container:not(.select-search-is-disabled).select-search-has-focus
  .select-search-input,
.select-search-container:not(.select-search-is-disabled)
  .select-search-input:hover {
  border-color: var(--select-search-selected);
}

.select-search-select {
  background: var(--select-search-background);
  box-shadow: 0 0.0625rem 0.125rem rgba(0, 0, 0, 0.15);
  border: 2px solid var(--select-search-border);
  overflow: auto;
  max-height: 360px;
}

.select-search-container:not(.select-search-is-multiple) .select-search-select {
  position: absolute;
  z-index: 2;
  top: 58px;
  right: 0;
  left: 0;
  border-radius: 3px;
  display: none;
}

.select-search-container:not(.select-search-is-multiple).select-search-has-focus
  .select-search-select {
  display: block;
}

.select-search-has-focus .select-search-select {
  border-color: var(--select-search-selected);
}

.select-search-options {
  list-style: none;
}

.select-search-option,
.select-search-not-found {
  display: block;
  height: 42px;
  width: 100%;
  padding: 0 16px;
  background: var(--select-search-background);
  border: none;
  outline: none;
  font-family: var(--select-search-font);
  color: var(--select-search-text);
  font-size: 16px;
  text-align: left;
  letter-spacing: 0.01rem;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
}

.select-search-option:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background: transparent !important;
}

.select-search-is-highlighted,
.select-search-option:not(.select-search-is-selected):hover {
  background: var(--select-search-highlight);
}

.select-search-is-selected {
  font-weight: bold;
  color: var(--select-search-selected);
}

.select-search-group-header {
  font-size: 12px;
  text-transform: uppercase;
  background: var(--select-search-border);
  color: var(--select-search-subtle-text);
  letter-spacing: 0.1rem;
  padding: 10px 16px;
}

.select-search-row:not(:first-child) .select-search-group-header {
  margin-top: 10px;
}

.select-search-row:not(:last-child) .select-search-group-header {
  margin-bottom: 10px;
}
