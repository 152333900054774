$blue: #007bff !default;
$indigo: #6610f2 !default;
$purple: #6f42c1 !default;
$pink: #e83e8c !default;
$red: #dc3545 !default;
$orange: #fd7e14 !default;
$yellow: #ffc107 !default;
$green: #28a745 !default;
$teal: #20c997 !default;
$cyan: #17a2b8 !default;
$white: #ffffff !default;

$c-border: #c7c7c7 !default;

$body-primary-color: #6a6c6f;
$text-primary: #6a6c6f;

// Botton color:
$btn-primary: #1d67b2 !default;
$btn-secondary: #ffffff !default;

$btn-border: #eaeaea !default;
$btn-white: #ffffff !default;

// Botton end

// Valiable color start

$login-plchdr: #bfbfbf;
$login-bg: #76b2ed;
$login-label: #666b4b;
$login-border: #bfbfbf;
$login-btn: #aeca0c;
$login-head: #ffffff;

$labelclr: #bfbfbf;
$graybg: #f7f7f7;
$atagclr: #1d67b2 !default;

// Valiable color end

$crl-2: #1d67b2 !default; //#6566b2 !default;
$light-b-color: #f7f9fa !default;
$checkbox-p: #1d67b2 !default;
$crl-3: #1d67b2 !default;
$btn-primary-light: #3739d0 !default;
