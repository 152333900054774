.c-navtab {
  padding: 6px;
  .nav-pills {
    .nav-link {
      font-size: 14px;
      border-radius: 0 !important;
      cursor: pointer;
      &.show {
        color: #fff;
        background-color: $crl-2;
        font-size: 14px;
      }
      &.active {
        color: #fff;
        background-color: $crl-2;
        font-size: 14px;
      }
    }
  }
}
.border-none {
  border: none;
}
.card-gray {
  background-color: $graybg;
  border: none;
}
