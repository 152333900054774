.btn {
  border-radius: 0.25rem !important;
  &:active,
  &:focus {
    box-shadow: none;
  }
}
.btn-m {
  border-radius: 4px;
  margin: 9px 12px !important;
}

.btn-primary {
  background-color: $btn-primary !important;
  border-color: $btn-primary !important;
}
.btn-secondary {
  background-color: $btn-secondary !important;
  border-color: $btn-secondary !important;
  color: $text-primary !important;
}

.btn-text-primary {
  color: $btn-primary !important;
}

.btn-size-sm {
  font-size: 14px;
}

.btn-left-icon {
  padding-left: 30px;
  position: relative;
  font-weight: 600;
  height: auto !important;
  &:before {
    position: absolute;
    left: 10px;
    font-family: "FontAwesome";
  }
  &.btn-edit {
    &:before {
      content: "\f040";
    }
  }
  &.btn-delete {
    &:before {
      content: "\f00d";
    }
  }
  &.btn-add {
    &:before {
      content: "\f067";
    }
  }
}

.download_pdf_btn {
  font-weight: 400;
  &:after {
    padding-left: 10px;
    font-family: "FontAwesome";
  }
  &.btn-pdf {
    &:after {
      content: "\f1c1";
    }
  }
}

.a-btn {
  font-size: 14px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-outline {
  background-color: $btn-white !important;
  border-color: $btn-border !important;
  &:hover {
    background-color: $crl-2 !important;
    border-color: $crl-2 !important;
    color: $white !important;
  }
}

.btn-outline-danger:hover {
  color: $atagclr !important;
}

.btn-outline-success {
  color: $btn-primary !important;
  &:hover {
    color: $btn-primary-light !important;
  }
}

.MuiButton-containedPrimary {
  background-color: $btn-primary !important;
  border-radius: 0.25rem !important;
  text-transform: none !important;
  font-size: 1rem;
  font-weight: 400;
}

.btn-hover {
  &:hover {
    text-decoration: underline;
  }
}
