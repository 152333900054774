ul.timeline {
  list-style-type: none;
  position: relative;
  &:before {
    content: " ";
    background: #d4d9df;
    display: inline-block;
    position: absolute;
    left: 29px;
    width: 2px;
    height: 100%;
    z-index: 400;
  }
  li {
    padding: 15px;
    background: $graybg;
    margin-left: 10px;
    margin-bottom: 8px;
    .timeline-badge {
      color: lightcoral;
      background-color: white;
      border-radius: 50%;
      border: 1px solid lightgray;
      display: inline-block;
      position: absolute;
      left: 15px;
      width: 30px;
      height: 30px;
      z-index: 400;
      line-height: 50px;
      font-size: 16px;
      text-align: center;
      border-top-right-radius: 50%;
      border-top-left-radius: 50%;
      border-bottom-right-radius: 50%;
      border-bottom-left-radius: 50%;
    }
    .timeline-badge i {
      position: absolute;
      top: 5px;
      left: 7px;
    }
    .float-right {
      font-size: small;
      color: lightslategray;
    }
    p {
      margin: 0;
    }
  }
}
