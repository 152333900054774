.dashboard_wrap {
  .dashboard-card {
    padding-right: 50px;
    &.db_icon {
      position: relative;
      &:after {
        position: absolute;
        right: 12px;
        top: 12px;
      }
      &.total-u:after {
        content: url(../../../img/total-users.png);
      }
      &.active-u:after {
        content: url(../../../img/active-users.png);
      }
      &.inactive-u:after {
        content: url(../../../img/inactive-users.png);
      }
    }

    h3 {
      font-size: 18px !important;
      font-weight: 600;
      margin-bottom: 5px;
    }

    span {
      font-size: 22px;
      font-weight: 600;
    }

    .recharts-legend-item {
      span {
        font-size: 14px;
      }
    }
  }
}
