.card-header {
  border-bottom: 1px solid rgb(234, 234, 234);
  font-weight: 600;
}

.card-header-gray {
  background-color: $graybg !important;
}
.card-header-white {
  background-color: $white !important;
}
.card-header-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
